import { FunctionComponent } from 'react';

import { styled, SvgIcon, SvgIconProps } from '@mui/material';

import MediaMonksIcon from './icon.svg';

const Icon = styled(MediaMonksIcon)(() => ({
  '&.MuiSvgIcon-root': {
    width: 'auto',
    height: 'auto',
  },
}));

const MediaMonks: FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon
    component={Icon}
    width="169"
    height="96"
    viewBox="0 0 169 96"
    {...props}
  />
);

export default MediaMonks;
