import { FunctionComponent } from 'react';

import { styled, SvgIcon, SvgIconProps } from '@mui/material';

import DownloadIcon from './icon.svg';

const Icon = styled(DownloadIcon)(() => ({
  '&.MuiSvgIcon-root': {
    width: 'auto',
    height: 'auto',
  },
}));

const Download: FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon
    component={Icon}
    width="18"
    height="16"
    viewBox="0 0 18 16"
    {...props}
  />
);

export default Download;
