import { FunctionComponent } from 'react';

import { styled, SvgIcon, SvgIconProps } from '@mui/material';

import CheckCircleIcon from './icon.svg';

const Icon = styled(CheckCircleIcon)(() => ({
  '&.MuiSvgIcon-root': {
    width: 'auto',
    height: 'auto',
  },
}));

const CheckCircle: FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon
    component={Icon}
    width="16"
    height="16"
    viewBox="0 0 46 46"
    {...props}
  />
);

export default CheckCircle;
