import { FunctionComponent } from 'react';

import { styled, SvgIcon, SvgIconProps } from '@mui/material';

import StanfordIcon from './icon.svg';

const Icon = styled(StanfordIcon)(() => ({
  '&.MuiSvgIcon-root': {
    width: 'auto',
    height: 'auto',
  },
}));

const Stanford: FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon
    component={Icon}
    width="105"
    height="56"
    viewBox="0 0 105 56"
    {...props}
  />
);

export default Stanford;
